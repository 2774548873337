export default {
  US: [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District of Columbia', value: 'DC' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Guam', value: 'GU' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'United States Minor Outlying Islands', value: 'UM' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virgin Islands, U.S.', value: 'VI' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
  ],
  CA: [
    { label: 'Alberta', value: 'AB' },
    { label: 'British Columbia', value: 'BC' },
    { label: 'Manitoba', value: 'MB' },
    { label: 'New Brunswick', value: 'NB' },
    { label: 'Newfoundland and Labrador', value: 'NL' },
    { label: 'Northwest Territories', value: 'NT' },
    { label: 'Nova Scotia', value: 'NS' },
    { label: 'Nunavut', value: 'NU' },
    { label: 'Ontario', value: 'ON' },
    { label: 'Prince Edward Island', value: 'PE' },
    { label: 'Quebec', value: 'QC' },
    { label: 'Saskatchewan', value: 'SK' },
    { label: 'Yukon', value: 'YT' },
  ],
  CN: [
    { label: 'Anhui', value: 'AH' },
    { label: 'Beijing', value: 'BJ' },
    { label: 'Chongqing', value: 'CQ' },
    { label: 'Fujian', value: 'FJ' },
    { label: 'Gansu', value: 'GS' },
    { label: 'Guangdong', value: 'GD' },
    { label: 'Guangxi', value: 'GX' },
    { label: 'Guizhou', value: 'GZ' },
    { label: 'Hainan', value: 'HI' },
    { label: 'Hebei', value: 'HE' },
    { label: 'Heilongjiang', value: 'HL' },
    { label: 'Henan', value: 'HA' },
    { label: 'Hong Kong SAR', value: 'HK' },
    { label: 'Hubei', value: 'HB' },
    { label: 'Hunan', value: 'HN' },
    { label: 'Jiangsu', value: 'JS' },
    { label: 'Jiangxi', value: 'JX' },
    { label: 'Jilin', value: 'JL' },
    { label: 'Liaoning', value: 'LN' },
    { label: 'Macao SAR', value: 'MO' },
    { label: 'Nei Mongol', value: 'NM' },
    { label: 'Ningxia', value: 'NX' },
    { label: 'Qinghai', value: 'QH' },
    { label: 'Shaanxi', value: 'SN' },
    { label: 'Shandong', value: 'SD' },
    { label: 'Shanghai', value: 'SH' },
    { label: 'Shanxi', value: 'SX' },
    { label: 'Sichuan', value: 'SC' },
    { label: 'Taiwan', value: 'TW' },
    { label: 'Tianjin', value: 'TJ' },
    { label: 'Xinjiang', value: 'XJ' },
    { label: 'Xizang', value: 'XZ' },
    { label: 'Yunnan', value: 'YN' },
    { label: 'Zhejiang', value: 'ZJ' },
  ],
};
