export default [
  { value: '@gmail.com' },
  { value: '@googlemail.com' },
  { value: '@yahoo.com' },
  { value: '@yahoo.co.uk' },
  { value: '@yahoo.co.jp' },
  { value: '@yahoo.com.br' },
  { value: '@yahoo.com.mx' },
  { value: '@outlook.com' },
  { value: '@hotmail.com' },
  { value: '@live.com' },
  { value: '@msn.com' },
  { value: '@icloud.com' },
  { value: '@aol.com' },
  { value: '@protonmail.com' },
  { value: '@protonmail.ch' },
  { value: '@tutanota.com' },
  { value: '@zoho.com' },
  { value: '@yandex.ru' },
  { value: '@mail.ru' },
  { value: '@163.com' },
  { value: '@126.com' },
  { value: '@qq.com' },
  { value: '@sina.com' },
  { value: '@aliyun.com' },
  { value: '@amazon.com' },
  { value: '@linkedin.com' },
  { value: '@facebook.com' },
  { value: '@twitter.com' },
];
