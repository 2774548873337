<template>
  <rhcn-drawer :open="open" @on-close="onClose">
    <div class="policy-content">
      <div class="title">1. What Data We Collect and Process</div>
      <div class="content">Ronghan International Limited ("Ronghan” or “we”) is a payment service provider and as such we provide acquiring services to customers. Being an acquirer means that we accept payment on behalf of the relevant merchant and then transfers the funds paid by the end user (“end user” or “you”) to the merchant. Ronghan’s role is to request the relevant payment scheme, such as Mastercard, Visa, to authorize the transaction and send this to your bank for approval. If this bank gives approval, Ronghan is notified of this by the relevant payment scheme and makes the payment to the merchant’s bank.We may collect (1) your device information; (2) billing information; (3) card payment information and other information relating to payment; (4) transaction information.</div>
      <div class="title">2. Why We Collect and Process Your Personal Data</div>
      <div class="content">We process your personal information for the following purposes:</div>
      <div class="content no-indent">
        1. To provide the service pursuant to the agreements with our partners/customers and the specific payment schemes;<br/>
        2. To comply with applicable laws and regulations;<br/>
        3. To conduct analysis for statistical, strategic and scientific purposes; and<br/>
        4. To protect our platform, systems and services from misuse, fraud, financial crimes or other unauthorised or illegal activity.
      </div>
      <div class="content">
        When we provide such acquiring service to our customer, we process your personal data as a data controller. It is important to us that your personal data is treated with care and that you are well informed about the way we process your personal data.
      </div>
      <div class="content margin-top-0">
        We process the data we need for providing acquiring services to our merchants which are typically web shops and brick-and-mortar stores selling you goods and services. This means that we receive your payment on behalf of the merchant and handle related matters around these financial transactions.
      </div>
      <div class="content margin-top-0">In addition, we process your data as a financial institution to monitor financial transactions for the purpose of preventing money laundering and terrorist financing.</div>
      <div class="title">3. Legal basis for processing your personal data</div>
      <div class="content">We will only collect your personal data in the scope provided by you. By submitting your data you agree to the processing of your transmitted data. Processing will be carried out on the basis of Art. 6 (1) GDPR with your consent. You can withdraw your consent at any time by contacting us without affecting the legality of the processing carried out with your consent up to the withdrawal. Specifically, as follows:</div>
      <div class="content">
        <el-table border :data="legalList" style="margin-top: 10px;">
          <el-table-column
            prop="purpose"
            label="Purpose Activity">
            <template slot-scope="scope">
              <div v-html="scope.row.purpose"></div>
            </template>
          </el-table-column>
          <el-table-column
            prop="type"
            label="Type of data">
            <template slot-scope="scope">
              <div v-html="scope.row.type"></div>
            </template>
          </el-table-column>
          <el-table-column
            prop="legal"
            label="Legal basis for processing including basis of legitimate interest (Article 6(1) of GDPR)">
            <template slot-scope="scope">
              <div v-html="scope.row.legal"></div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="title">4. Cookies </div>
      <div class="content">We use cookies and similar techniques, such as tags/beacons and javascripts, which are small text files stored on your device. Using cookies is a way for us to make sure that our website is continuously improved, meets your needs and can be used as a tool to optimize our marketing strategy. In order for us to do this, we place functional cookies to make the website function as well as marketing cookies which help us target the right people and show them advertisements. Some of these cookies track your use of our website and visits to other websites and allow us to show you advertisements when you browse other websites. Please refer to our cookie policy for further information about how these technologies are used on our website.</div>
      <div class="title">5. With whom do we share this information?</div>
      <div class="content">Where necessary we will share your information with our service providers and professional advisers (e.g., IT providers, KYC partners, marketing support providers (such as agencies which manage our social media accounts), social media providers, analysts, customer service providers, business development providers and legal service providers). We have concluded agreements with our service providers to protect your personal data. we may be required to disclose personal data in response to lawful requests from officials (such as law enforcement or security authorities).
        If our business is sold or integrated with another business, your details will be disclosed to our advisers and any prospective purchaser’s adviser and will be passed to the new owners of the business.</div>
      <div class="content margin-top-0">Otherwise we will not share your information with any third party, unless we have your permission (for example if we wish to share your details with another group company for their recruitment purposes), where this is necessary in connection with the purposes above or with legal claims or when we have a legal obligation to do so.</div>
      <div class="title">6. Data Retention</div>
      <div class="content">We only keep your data for as long as we reasonably need it for the purposes listed above. Your personal data will be stored in UK.</div>
      <div class="content margin-top-0">We keep the data we collect in order to perform the transaction in accordance with applicable laws after conclusion of the relevant transaction, to meet our fiscal, corporate and other statutory obligations. Some of the information send to us may be disclosed to companies outside of the European Economic Area ("EEA"), when this is necessary for the purposes mentioned above. These countries include the countries in which we have operation. It also includes the countries in which some of our service providers are locate. To protect your data when these are transferred to countries outside of the EEA, we have implemented appropriate safeguards. For example, we will transfer your data outside EEA with your explicit consent, or on the basis of specific safeguards in individual circumstances of which we’ll let you know if they apply to your data.</div>
      <div class="title">7. Cross-border data transfer </div>
      <div class="content">We are a global business. We may transfer your personal data to countries other than your own country. These countries may have data protection rules that are different from your country. When transferring data across borders, we take measures to comply with applicable data protection laws related to such transfer. Where applicable law requires a data transfer mechanism, we use one or more of the following: </div>
      <div class="content margin-top-0">Transfers to certain countries or recipients that are recognised as having an adequate level of protection for personal data under applicable law. </div>
      <div class="content margin-top-0">EU Standard Contractual Clauses approved by the European Commission and the UK International Data Transfer Addendum issued by the Information Commissioner’s Office or other legal methods available to us under applicable law.</div>
      <div class="title">8. Your Rights and Choices</div>
      <div class="content">Depending on your location and subject to applicable law, you may have the followings rights:</div>
      <div class="content no-indent">
        Right to access<br/>
        Right of rectification<br/>
        Right to data portability<br/>
        Right to restrict processing<br/>
        Right to object to processing<br/>
        Right to withdraw consent (where it is relied upon)<br/>
        Right to erasure/deletion<br/>
        Right to opt-out of receiving electronic communications from us<br/>
        Right to non-discrimination for exercising your rights<br/>
        Right to opt-out from a sale of personal information<br/>
        Right to opt-out of “sharing” under California privacy law<br/>
        Right to limit the use or sharing of sensitive personal information<br/>
        Right to appeal our response to your data subject request.<br/>
        You have the right to complain to your local data protection authority if you are unhappy with our privacy practices.
      </div>
      <div class="title">9. How do we protect your data?</div>
      <div class="content">Personal data shall be subject to additional safeguards to ensure this data is processed securely. For example, we work hard to ensure data is encrypted when in transit and storage, and access to this data will be strictly limited to a minimum number of individuals and subject to confidentiality commitments.</div>
      <div class="content margin-top-0">We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Policy. Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to any of our websites.</div>
      <div class="title">10. Contact and Complain</div>
      <div class="content">If you have any questions or complaints regarding the treatment of your personal data, please contact our data protection officer:<br>Email Address ：<a href="mailto:dpo@onerway.com">dpo@onerway.com</a></div>
    </div>
  </rhcn-drawer>
</template>
<script>
import RhcnDrawer from '@/components/drawer/index.vue';

export default {
  name: 'privacy',
  components: {
    RhcnDrawer,
  },
  props: {
    open: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      legalList: [
        {
          purpose: 'To use data analytics to improve our website, products/services, marketing, customer relationships and experiences',
          type: 'Device information',
          legal: '<ul><li>Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)</li></ul>',
        },
        {
          purpose: 'To facilitate and enable our relationship with you as a prospective, new or existing merchant',
          type: 'Billing information and card information',
          legal: '<ul><li>Performance of a contract with you;</li><li>Necessary to comply with a legal obligation</li><li>Necessary for our legitimate interests (for running our business and to prevent fraud)</li></ul>',
        },
        {
          purpose: 'To process and execute your transaction, and other payments related activities, including:<ul><li>Manage payments, fees and charges</li><li>Collect and recover funds for our business purposes</li></ul>',
          type: 'Billing information, card information and transaction information',
          legal: '<ul><li>Performance of a contract with you</li><li>Necessary to comply with a legal obligation</li><li>Necessary for our legitimate interests (for running our business and to prevent fraud)</li><li>Processing of your personal data for this purpose may include automated decision-making</li></ul>',
        },
        {
          purpose: '<ul><li>To manage our relationship with you as a user of our website or Services, which will include:</li><li>Notifying you about changes to our Service, terms or privacy policy;</li><li>Provide Services to you</li></ul>',
          type: 'Billing information, card information and transaction information',
          legal: '<ul><li>Performance of a contract with you</li><li>Necessary to comply with a legal obligation</li><li>Necessary for our legitimate interests (to keep our records updated and to study how customers use our products and services)</li></ul>',
        },
        {
          purpose: 'To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)',
          type: 'Billing information, and card information',
          legal: '<ul><li>Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)</li><li>Necessary to comply with a legal obligation</li></ul>',
        },
      ],
    };
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
  },
};
</script>
<style lang="scss">
.policy-content {
  text-align: left;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 0 120px;
  .title {
    font-size: 18px;
    font-weight: 500;
    color: #27272B;
    line-height: 25px;
    margin-top: 25px;
    &:first-child {
      margin-top: 30px;
    }
  }
  .content {
    font-size: 14px;
    font-weight: normal;
    color: #5E6D7B;
    line-height: 20px;
    text-indent: 2em;
    margin-top: 10px;
    &.no-indent {
      text-indent: 0;
    }
    &.margin-top-0 {
      margin-top: 0;
    }
    ul {
      padding: 0 0 0 12px;
      margin: 0;
    }
    a {
      text-decoration: none;
    }
  }
  thead {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: #27272B;
  }
  tbody {
    font-size: 14px;
    font-weight: normal;
    color: #5E6D7B;
    line-height: 20px;
  }
}
@media screen and (max-width: 1140px) {
  .policy-content {
    padding: 0 10px 120px;
  }
}
</style>
