export default [
  { code: 'AD', fullName: '安道尔 Andorra', name: 'Andorra' },
  { code: 'AF', fullName: '阿富汗 Afghanistan', name: 'Afghanistan' },
  { code: 'EG', fullName: '埃及 Egypt', name: 'Egypt' },
  { code: 'EH', fullName: '西撒哈拉 Western Sahara', name: 'Western Sahara' },
  { code: 'FO', fullName: '法罗群岛 Faroe Islands', name: 'Faroe Islands' },
  { code: 'GA', fullName: '加蓬 Gabon', name: 'Gabon' },
  { code: 'GD', fullName: '格林纳达 Grenada', name: 'Grenada' },
  { code: 'GI', fullName: '直布罗陀 Gibraltar', name: 'Gibraltar' },
  { code: 'GM', fullName: '冈比亚Gambia', name: 'Gambia' },
  { code: 'AG', fullName: '安提瓜和巴布达 Antigua and Barbuda', name: 'Antigua and Barbuda' },
  { code: 'AI', fullName: '安圭拉 Anguilla', name: 'Anguilla' },
  { code: 'AL', fullName: '阿尔巴尼亚 Albania', name: 'Albania' },
  { code: 'AN', fullName: '荷属安的列斯 Netherlands Antilles', name: 'Netherlands Antilles' },
  { code: 'AO', fullName: '安哥拉 Angola', name: 'Angola' },
  { code: 'AQ', fullName: '南极洲 Antarctica', name: 'Antarctica' },
  { code: 'AR', fullName: '阿根廷 Argentina', name: 'Argentina' },
  { code: 'AU', fullName: '澳大利亚 Australia', name: 'Australia' },
  { code: 'AW', fullName: '阿鲁巴 Aruba', name: 'Aruba' },
  { code: 'AZ', fullName: '阿塞拜疆 Azerbaijan', name: 'Azerbaijan' },
  { code: 'BA', fullName: '波斯尼亚和黑塞哥维那 Bosnia and Herzegovina', name: 'Bosnia and Herzegovina' },
  { code: 'BB', fullName: '巴巴多斯 Barbados', name: 'Barbados' },
  { code: 'BF', fullName: '布基纳法索 Burkina Faso', name: 'Burkina Faso' },
  { code: 'BG', fullName: '保加利亚 Bulgaria', name: 'Bulgaria' },
  { code: 'BH', fullName: '巴林 Bahrain', name: 'Bahrain' },
  { code: 'BI', fullName: '布隆迪 Burundi', name: 'Burundi' },
  { code: 'BJ', fullName: '贝宁 Benin', name: 'Benin' },
  { code: 'BM', fullName: '百慕大 Bermuda', name: 'Bermuda' },
  { code: 'BS', fullName: '巴哈马 Bahamas', name: 'Bahamas' },
  { code: 'BV', fullName: '布维岛 Bouvet Island', name: 'Bouvet Island' },
  { code: 'BW', fullName: '博茨瓦纳 Botswana', name: 'Botswana' },
  { code: 'BZ', fullName: '伯利兹 Belize', name: 'Belize' },
  { code: 'CA', fullName: '加拿大 Canada', name: 'Canada' },
  { code: 'CF', fullName: '中非 Central Africa', name: 'Central Africa' },
  { code: 'CG', fullName: '刚果 Congo', name: 'Congo' },
  { code: 'CI', fullName: '科特迪瓦 Cote d\'Ivoire', name: 'Cote d\'Ivoire' },
  { code: 'CK', fullName: '库克群岛 Cook Islands', name: 'Cook Islands' },
  { code: 'CN', fullName: '中国 China', name: 'China' },
  { code: 'CU', fullName: '古巴 Cuba', name: 'Cuba' },
  { code: 'CV', fullName: '佛得角 Cape Verde', name: 'Cape Verde' },
  { code: 'DE', fullName: '德国 Germany', name: 'Germany' },
  { code: 'EC', fullName: '厄瓜多尔 Ecuador', name: 'Ecuador' },
  { code: 'GR', fullName: '希腊 Greece', name: 'Greece' },
  { code: 'IR', fullName: '伊朗 Iran', name: 'Iran' },
  { code: 'IT', fullName: '意大利 Italy', name: 'Italy' },
  { code: 'JM', fullName: '牙买加 Jamaica', name: 'Jamaica' },
  { code: 'JO', fullName: '约旦 Jordan', name: 'Jordan' },
  { code: 'JP', fullName: '日本 Japan', name: 'Japan' },
  { code: 'KE', fullName: '肯尼亚 Kenya', name: 'Kenya' },
  { code: 'KM', fullName: '科摩罗 Comoros', name: 'Comoros' },
  { code: 'KP', fullName: '朝鲜 Korea,Democratic People\'s Republic of', name: 'Democratic People\'s Republic of' },
  { code: 'KR', fullName: '韩国 Korea,Republic of', name: 'Republic of' },
  { code: 'LB', fullName: '黎巴嫩 Lebanon', name: 'Lebanon' },
  { code: 'LS', fullName: '莱索托 Lesotho', name: 'Lesotho' },
  { code: 'LU', fullName: '卢森堡 Luxembourg', name: 'Luxembourg' },
  { code: 'LV', fullName: '拉脱维亚 Latvia', name: 'Latvia' },
  { code: 'MD', fullName: '摩尔多瓦 Moldova', name: 'Moldova' },
  { code: 'GU', fullName: '关岛 Guam', name: 'Guam' },
  { code: 'HK', fullName: '香港 Hong Kong', name: 'Hong Kong' },
  { code: 'HM', fullName: '赫德岛和麦克唐纳岛 Heard islands and Mc Donald Islands', name: 'Heard islands and Mc Donald Islands' },
  { code: 'HT', fullName: '海地 Haiti', name: 'Haiti' },
  { code: 'MK', fullName: '马斯顿 Macedonia', name: 'Macedonia' },
  { code: 'ML', fullName: '马里 Mali', name: 'Mali' },
  { code: 'MN', fullName: '蒙古 Mongolia', name: 'Mongolia' },
  { code: 'HU', fullName: '匈牙利 Hungary', name: 'Hungary' },
  { code: 'ID', fullName: '印度尼西亚 Indonesia', name: 'Indonesia' },
  { code: 'IE', fullName: '爱尔兰 Ireland', name: 'Ireland' },
  { code: 'IL', fullName: '以色列 Israel', name: 'Israel' },
  { code: 'IN', fullName: '印度 India', name: 'India' },
  { code: 'IQ', fullName: '伊拉克 Iraq', name: 'Iraq' },
  { code: 'MT', fullName: '马耳他 Malta', name: 'Malta' },
  { code: 'MV', fullName: '马尔代夫 Maldives', name: 'Maldives' },
  { code: 'MX', fullName: '墨西哥 Mexico', name: 'Mexico' },
  { code: 'NC', fullName: '新喀里多尼亚 New Caledonia', name: 'New Caledonia' },
  { code: 'NI', fullName: '尼加拉瓜 Nicaragua', name: 'Nicaragua' },
  { code: 'NZ', fullName: '新西兰 New Zealand', name: 'New Zealand' },
  { code: 'PE', fullName: '秘鲁 Peru', name: 'Peru' },
  { code: 'PL', fullName: '波兰 Poland', name: 'Poland' },
  { code: 'PT', fullName: '葡萄牙 Portugal', name: 'Portugal' },
  { code: 'PW', fullName: '贝劳 Palau', name: 'Palau' },
  { code: 'CR', fullName: '哥斯达黎加 Costa Rica', name: 'Costa Rica' },
  { code: 'CZ', fullName: '捷克 Czech Repoublic', name: 'Czech Repoublic' },
  { code: 'DZ', fullName: '阿尔及利亚 Algeria', name: 'Algeria' },
  { code: 'ET', fullName: '埃塞俄比亚 Ethiopia', name: 'Ethiopia' },
  { code: 'FR', fullName: '法国 France', name: 'France' },
  { code: 'RO', fullName: '罗马尼亚 Romania', name: 'Romania' },
  { code: 'RU', fullName: '俄罗斯 Russia', name: 'Russia' },
  { code: 'SA', fullName: '沙竺阿拉伯 Saudi Arabia', name: 'Saudi Arabia' },
  { code: 'SB', fullName: '所罗门群岛 Solomon Islands', name: 'Solomon Islands' },
  { code: 'SC', fullName: '塞舌尔 Seychells', name: 'Seychells' },
  { code: 'SD', fullName: '苏丹 Sudan', name: 'Sudan' },
  { code: 'SE', fullName: '瑞典 Sweden', name: 'Sweden' },
  { code: 'SG', fullName: '新加坡 Singapore', name: 'Singapore' },
  { code: 'SI', fullName: '斯洛文尼亚 Slovenia', name: 'Slovenia' },
  { code: 'SJ', fullName: '斯瓦尔巴群岛 Svalbard and jan Mayen Islands', name: 'Svalbard and jan Mayen Islands' },
  { code: 'SK', fullName: '斯洛伐克 Slovakia', name: 'Slovakia' },
  { code: 'SL', fullName: '塞拉利昂 Sierra leone', name: 'Sierra leone' },
  { code: 'SN', fullName: '塞内加尔 Senegal', name: 'Senegal' },
  { code: 'SO', fullName: '索马里 Somalia', name: 'Somalia' },
  { code: 'SR', fullName: '苏里南 Suriname', name: 'Suriname' },
  { code: 'ST', fullName: '圣多美和普林西比 Sao Tome and Principe', name: 'Sao Tome and Principe' },
  { code: 'SV', fullName: '萨尔瓦多 El Salvador', name: 'El Salvador' },
  { code: 'SZ', fullName: '斯威士兰 Swaziland', name: 'Swaziland' },
  { code: 'TC', fullName: '特克斯科斯群岛 Turks and Caicos Islands', name: 'Turks and Caicos Islands' },
  { code: 'TD', fullName: '乍得 Chad', name: 'Chad' },
  { code: 'TF', fullName: '法属南部领土 French Southern Territo-ries', name: 'French Southern Territo-ries' },
  { code: 'TG', fullName: '多哥 Togo', name: 'Togo' },
  { code: 'TH', fullName: '泰国 Thailand', name: 'Thailand' },
  { code: 'TK', fullName: '托克劳 Tokelau', name: 'Tokelau' },
  { code: 'TM', fullName: '土库曼斯坦 Turkmenistan', name: 'Turkmenistan' },
  { code: 'TN', fullName: '突尼斯 Tunisia', name: 'Tunisia' },
  { code: 'TP', fullName: '东帝汶 East Timor', name: 'East Timor' },
  { code: 'TT', fullName: '特立尼达和多巴哥 Trinidad and Tobago', name: 'Trinidad and Tobago' },
  { code: 'UA', fullName: '乌克兰 Ukraine', name: 'Ukraine' },
  { code: 'US', fullName: '美国 United States', name: 'United States' },
  { code: 'UZ', fullName: '乌兹别克斯坦 Uzbekistan', name: 'Uzbekistan' },
  { code: 'VA', fullName: '梵蒂冈 Vatican', name: 'Vatican' },
  { code: 'VE', fullName: '委内瑞拉 Venezuela', name: 'Venezuela' },
  { code: 'VG', fullName: '英属维尔京群岛 British Virgin Islands', name: 'British Virgin Islands' },
  { code: 'VU', fullName: '瓦努阿图 Vanuatu', name: 'Vanuatu' },
  { code: 'YE', fullName: '也门 Yemen', name: 'Yemen' },
  { code: 'YT', fullName: '马约特 Mayotte', name: 'Mayotte' },
  { code: 'YU', fullName: '南斯拉夫 Yugoslavia', name: 'Yugoslavia' },
  { code: 'ZA', fullName: '南非 South Africa', name: 'South Africa' },
  { code: 'ZW', fullName: '津巴布韦 Zimbabwe', name: 'Zimbabwe' },
  { code: 'GB', fullName: '英国 United Kingdom', name: 'United Kingdom' },
  { code: 'BO', fullName: '玻利维亚 Bolivia', name: 'Bolivia' },
  { code: 'BT', fullName: '不丹 Bhutan', name: 'Bhutan' },
  { code: 'BY', fullName: '白俄罗斯 Belarus', name: 'Belarus' },
  { code: 'CC', fullName: '科科斯(基林)群岛 Cocos(Keeling) Islands', name: 'Cocos(Keeling) Islands' },
  { code: 'CL', fullName: '智利 Chile', name: 'Chile' },
  { code: 'CO', fullName: '哥伦比亚 Colombia', name: 'Colombia' },
  { code: 'TW', fullName: '中国台湾 Taiwan,China', name: 'Taiwan,China' },
  { code: 'CY', fullName: '塞浦路斯 Cyprus', name: 'Cyprus' },
  { code: 'DK', fullName: '丹麦 Denmark', name: 'Denmark' },
  { code: 'DO', fullName: '多米尼加共和国 Dominican Republic', name: 'Dominican Republic' },
  { code: 'ES', fullName: '西班牙 Spain', name: 'Spain' },
  { code: 'FI', fullName: '芬兰 Finland', name: 'Finland' },
  { code: 'FK', fullName: '马尔维纳斯群岛(福克兰群岛) Malvinas Islands (Falkland Islands)', name: 'Malvinas islands (Falkland Islands)' },
  { code: 'GE', fullName: '格鲁吉亚 Georgia', name: 'Georgia' },
  { code: 'GL', fullName: '格陵兰 Greenland', name: 'Greenland' },
  { code: 'GN', fullName: '几内亚 Guinea', name: 'Guinea' },
  { code: 'GS', fullName: '南乔治亚岛和南桑德韦奇岛 South Georgia and South Sandwich Islands', name: 'South Georgia and South Sandwich Islands' },
  { code: 'KW', fullName: '科威特 Kuwait', name: 'Kuwait' },
  { code: 'KZ', fullName: '哈萨克斯坦 Kazakhstan', name: 'Kazakhstan' },
  { code: 'LT', fullName: '立陶宛 Lithuania', name: 'Lithuania' },
  { code: 'LY', fullName: '利比亚 Libya', name: 'Libya' },
  { code: 'MH', fullName: '马绍尔群岛 Marshall Islands', name: 'Marshall Islands' },
  { code: 'MO', fullName: '澳门 Macau', name: 'Macau' },
  { code: 'MU', fullName: '毛里求斯 Mauritius', name: 'Mauritius' },
  { code: 'MY', fullName: '马来西亚 Malaysia', name: 'Malaysia' },
  { code: 'NA', fullName: '纳米比亚 Namibia', name: 'Namibia' },
  { code: 'NU', fullName: '纽埃 Niue', name: 'Niue' },
  { code: 'OM', fullName: '阿曼 Oman', name: 'Oman' },
  { code: 'PK', fullName: '巴基斯坦 Pakistan', name: 'Pakistan' },
  { code: 'PR', fullName: '波多黎各 Puerto Rico', name: 'Puerto Rico' },
  { code: 'AT', fullName: '奥地利 Austria', name: 'Austria' },
  { code: 'BD', fullName: '孟加拉国 Bangladesh', name: 'Bangladesh' },
  { code: 'CH', fullName: '瑞士 Switzerland', name: 'Switzerland' },
  { code: 'CS', fullName: '圣诞岛 Christmas Island', name: 'Christmas Island' },
  { code: 'FM', fullName: '密克罗尼西亚 Micronesia', name: 'Micronesia' },
  { code: 'IO', fullName: '英属印度洋领土 British indian Ocean Ter-ritory', name: 'British indian Ocean Ter-ritory' },
  { code: 'KI', fullName: '基里巴斯 Kiribati', name: 'Kiribati' },
  { code: 'LC', fullName: '圣卢西亚 Saint lucia', name: 'Saint lucia' },
  { code: 'MA', fullName: '摩洛哥 Morocco', name: 'Morocco' },
  { code: 'MM', fullName: '缅甸 Myanmar', name: 'Myanmar' },
  { code: 'MS', fullName: '蒙特塞拉特 Montserrat', name: 'Montserrat' },
  { code: 'NG', fullName: '尼日利亚 Nigeria', name: 'Nigeria' },
  { code: 'PH', fullName: '菲律宾 Philippines', name: 'Philippines' },
  { code: 'RE', fullName: '留尼汪 Reunion', name: 'Reunion' },
  { code: 'WS', fullName: '西萨摩亚 Western Samoa', name: 'Western Samoa' },
  { code: 'AE', fullName: '阿闻酋 United Arab Emirates', name: 'United Arab Emirates' },
  { code: 'AS', fullName: '美属萨摩亚 American Samoa', name: 'American Samoa' },
  { code: 'BE', fullName: '比利时 Belgium', name: 'Belgium' },
  { code: 'BN', fullName: '文莱 Brunei Darussalam', name: 'Brunei Darussalam' },
  { code: 'CM', fullName: '喀麦隆 Cameroon', name: 'Cameroon' },
  { code: 'DJ', fullName: '吉布提 Djibouti', name: 'Djibouti' },
  { code: 'ER', fullName: '厄立特里亚 Eritrea', name: 'Eritrea' },
  { code: 'GF', fullName: '法属圭亚那 French Guiana', name: 'French Guiana' },
  { code: 'GW', fullName: '几内亚比绍 Guine-bissau', name: 'Guine-bissau' },
  { code: 'HR', fullName: '克罗地亚 Croatia', name: 'Croatia' },
  { code: 'IS', fullName: '冰岛 Iceland', name: 'Iceland' },
  { code: 'KH', fullName: '柬埔寨 Cambodia', name: 'Cambodia' },
  { code: 'KY', fullName: '开曼群岛 Cayman Islands', name: 'Cayman Islands' },
  { code: 'LR', fullName: '利比里亚 Liberia', name: 'Liberia' },
  { code: 'MQ', fullName: '马提尼克 Martinique', name: 'Martinique' },
  { code: 'MZ', fullName: '莫桑比克 Mozambique', name: 'Mozambique' },
  { code: 'PA', fullName: '巴拿马 Panama', name: 'Panama' },
  { code: 'PN', fullName: '皮特凯恩群岛 Pitcairn Islands Group', name: 'Pitcairn Islands Group' },
  { code: 'RW', fullName: '卢旺达 Rwanda', name: 'Rwanda' },
  { code: 'SH', fullName: '圣赫勒拿 Saint helena', name: 'Saint helena' },
  { code: 'SM', fullName: '圣马力诺 San Marion', name: 'San Marion' },
  { code: 'SY', fullName: '叙利亚 Syria', name: 'Syria' },
  { code: 'TJ', fullName: '塔吉克斯坦 Tajikistan', name: 'Tajikistan' },
  { code: 'TZ', fullName: '坦桑尼亚 Tanzania', name: 'Tanzania' },
  { code: 'EE', fullName: '爱沙尼亚 Estonia', name: 'Estonia' },
  { code: 'LI', fullName: '列支敦士登 Liechtenstein', name: 'Liechtenstein' },
  { code: 'NF', fullName: '诺福克岛 Norfolk Island', name: 'Norfolk Island' },
  { code: 'PY', fullName: '巴拉圭 Paraguay', name: 'Paraguay' },
  { code: 'GP', fullName: '瓜德罗普 Guadeloupe', name: 'Guadeloupe' },
  { code: 'UM', fullName: '美属太平洋各群岛(包括:中途岛、约翰斯顿岛、豪兰岛、贝克岛和威克岛等) United States miscella-neous Pac', name: 'United States miscella-neous Pac' },
  { code: 'NP', fullName: '尼泊尔 Nepal', name: 'Nepal' },
  { code: 'PM', fullName: '圣皮埃尔和密克隆 Saint Pierre and Miquelon', name: 'Saint Pierre and Miquelon' },
  { code: 'GQ', fullName: '赤道几内亚 Equatorial Guinea', name: 'Equatorial Guinea' },
  { code: 'GY', fullName: '圭亚那 Guyana', name: 'Guyana' },
  { code: 'KG', fullName: '吉尔吉斯斯坦 Kyrgyzstan', name: 'Kyrgyzstan' },
  { code: 'KN', fullName: '圣基茨和尼维斯 Saint Kitts and nevis', name: 'Saint Kitts and nevis' },
  { code: 'LA', fullName: '老挝 Lao', name: 'Lao' },
  { code: 'LK', fullName: '斯里兰卡 Sri Lanka', name: 'Sri Lanka' },
  { code: 'MC', fullName: '摩纳哥 Monaco', name: 'Monaco' },
  { code: 'MG', fullName: '马达加斯加 Madagascar', name: 'Madagascar' },
  { code: 'MR', fullName: '毛里塔尼亚 Mauritania', name: 'Mauritania' },
  { code: 'MW', fullName: '马拉维 Malawi', name: 'Malawi' },
  { code: 'NE', fullName: '尼日尔 Niger', name: 'Niger' },
  { code: 'NO', fullName: '挪威 Norway', name: 'Norway' },
  { code: 'NR', fullName: '瑙鲁 Nauru', name: 'Nauru' },
  { code: 'PG', fullName: '巴布亚新几内亚 Papua New Guinea', name: 'Papua New Guinea' },
  { code: 'PS', fullName: '巴勒斯坦 Palestine', name: 'Palestine' },
  { code: 'QA', fullName: '卡塔尔 Qatar', name: 'Qatar' },
  { code: 'RS', fullName: '塞尔维亚 Serbia', name: 'Serbia' },
  { code: 'MP', fullName: '北马里亚纳 Northern Marianas', name: 'Northern Marianas' },
  { code: 'NL', fullName: '荷兰 Netherlands', name: 'Netherlands' },
  { code: 'PF', fullName: '法属波利尼西亚 French Polynesia', name: 'French Polynesia' },
  { code: 'AM', fullName: '亚美尼亚 Armenia', name: 'Armenia' },
  { code: 'BR', fullName: '巴西 Brazil', name: 'Brazil' },
  { code: 'DM', fullName: '多米尼克 Dominica', name: 'Dominica' },
  { code: 'HN', fullName: '洪都拉斯 Honduras', name: 'Honduras' },
  { code: 'TO', fullName: '汤加 Tonga', name: 'Tonga' },
  { code: 'TR', fullName: '土耳其 Turkey', name: 'Turkey' },
  { code: 'TV', fullName: '图瓦卢 Tuvalu', name: 'Tuvalu' },
  { code: 'UG', fullName: '乌干达 Uganda', name: 'Uganda' },
  { code: 'UY', fullName: '乌拉圭 Uruguay', name: 'Uruguay' },
  { code: 'VC', fullName: '圣文森特和格林纳丁斯 Saint Vincent and the Grenadines', name: 'Saint Vincent and the Grenadines' },
  { code: 'VI', fullName: '美属维尔京群岛 United States Virgin Is-lands', name: 'United States Virgin Is-lands' },
  { code: 'WF', fullName: '瓦利斯和富图纳群岛 Wallis and Futuna Is-lands', name: 'Wallis and Futuna Is-lands' },
  { code: 'ZM', fullName: '赞比亚 Zambia', name: 'Zambia' },
  { code: 'FJ', fullName: '斐济 Fiji', name: 'Fiji' },
  { code: 'GH', fullName: '加纳 Ghana', name: 'Ghana' },
  { code: 'GT', fullName: '危地马拉 Guatemala', name: 'Guatemala' },
  { code: 'VN', fullName: '越南 Viet Nam', name: 'Viet Nam' },
  { code: 'ZR', fullName: '扎伊尔 Zaire', name: 'Zaire' },
];
