<template>
  <el-button class="rhcn-button" :type="type" :size="size" :loading="loading" @click="clickHandler" :disabled="disabled" :circle="circle">
    <!-- primary button loading -->
    <svg v-if="loading && type==='primary'"
      t="1664453412836"
      class="rhcn-loading"
      viewBox="0 0 1024 1024"
      version="1.1" xmlns="http://www.w3.org/2000/svg"
      p-id="2689"
      width="21"
      height="21">
      <path d="M512 512m-426.666667 0a426.666667 426.666667 0 1 0 853.333334 0 426.666667 426.666667 0 1 0-853.333334 0Z" fill="#FFF" fill-opacity=".3" p-id="2690"></path>
      <path d="M938.666667 512c0-235.648-191.018667-426.666667-426.666667-426.666667v426.666667h426.666667z" fill="#FFF" p-id="2691"></path>
      <path d="M512 512m-320 0a320 320 0 1 0 640 0 320 320 0 1 0-640 0Z" fill="#00A2DD" p-id="2692"></path>
    </svg>
    <!-- default button loading -->
    <svg v-if="loading && type==='default'"
      t="1664453412836"
      class="rhcn-loading"
      viewBox="0 0 1024 1024"
      version="1.1" xmlns="http://www.w3.org/2000/svg"
      p-id="2689"
      width="21"
      height="21">
      <path d="M512 512m-426.666667 0a426.666667 426.666667 0 1 0 853.333334 0 426.666667 426.666667 0 1 0-853.333334 0Z" fill="#00A2DD" fill-opacity=".3" p-id="2690"></path>
      <path d="M938.666667 512c0-235.648-191.018667-426.666667-426.666667-426.666667v426.666667h426.666667z" fill="#00A2DD" p-id="2691"></path>
      <path d="M512 512m-320 0a320 320 0 1 0 640 0 320 320 0 1 0-640 0Z" fill="#F1F5F7" p-id="2692"></path>
    </svg>
    <slot></slot>
  </el-button>
</template>
<script>
export default {
  name: 'rhcn-button',
  props: {
    icon: String,
    size: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickHandler() {
      this.$emit('click');
    },
  },
};
</script>
<style scoped lang="scss">
.rhcn-loading {
  animation: loading-rotate 2s linear infinite;
}
.rhcn-button {
  &.el-button--small {
    .rhcn-loading {
      width: 14px;
      height: 14px;
    }
  }
  &.is-circle {
    border-radius: 50%;
  }
}
</style>
