<template>
  <div class="i-input-wrapper">
    <el-input
      class="i-input cardNumber"
      maxlength="23"
      autocomplete="cc-number"
      v-model="cardNumber"
      autofocus
      @input.native="changeHandler"
      @blur="blurHandler"
      @focus="focusHandler"
      @change="valueChangeHandler"
      inputmode="numeric"
      ref="cardInput">
      <div slot="suffix" class="suffix-card">
        <span class="split"></span><img class="card" v-if="card" :src="card.logoPath" height="23" />
        <span class="iconfont icon-guanbi" v-if="cardNumber.length" @click="clearCardNumber"></span>
      </div>
    </el-input>
  </div>
</template>
<script>
import { keydownHandler } from '@/utils/tools/utils';
import { getCard } from '@/utils/validator';

export default {
  props: {
    value: { type: String, default: '' },
    supportCards: { type: Array, default: () => [] },
  },
  data() {
    return {
      card: { logoPath: require('@/assets/images/cards/Default.png') },
      cardNumber: this.value,
    };
  },
  methods: {
    keydown(evt) {
      keydownHandler(evt, 'number');
    },
    changeHandler(evt) {
      const selectionStart = evt.target.selectionStart;
      let formattedNumber = this.cardNumber.replace(/\D/g, '');
      formattedNumber = formattedNumber.replace(/(.{4})/g, '$1 ').trim();
      this.cardNumber = formattedNumber;
      this.$emit('update:value', this.cardNumber);
      this.card = getCard(this.cardNumber);
      if (!this.card) {
        this.card = { logoPath: require('@/assets/images/cards/Default.png') };
      }
      this.$nextTick(() => {
        let s = 0;
        if (evt.data === null && selectionStart % 5 === 0) { // 删除光标位置不变
          s = 0;
        } else if (evt.data !== null && !/^\d$/.test(evt.data)) { // 输入非数字光标位置-1
          s = -1;
        } else if (selectionStart % 5 === 0) { // 碰到空格光标位置+1
          s = 1;
        }
        evt.target.selectionStart = evt.target.selectionEnd = selectionStart + s;
      });
    },
    focusHandler(evt) {
      this.$emit('focus', evt);
    },
    blurHandler(evt) {
      this.$emit('blur', evt);
    },
    valueChangeHandler(evt) {
      this.$emit('change', evt);
    },
    clearCardNumber() {
      this.cardNumber = '';
      this.card = { logoPath: require('@/assets/images/cards/Default.png') };
      this.$emit('update:value', this.cardNumber);
    },
  },
  watch: {
    value: {
      handler(curValue) {
        this.cardNumber = curValue;
      },
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/styles/variables.scss';

.i-input-wrapper {
  .cardNumber {
    ::v-deep .el-input__inner {
      padding-right: 90px;
    }
  }
  .suffix-card {
    height: 23px;
    display: flex;
    align-items: center;
    .split {
      display: inline-block;
      height: 16px;
      width: 1px;
      background: $color-border-light;
      margin-right: 10px;
      vertical-align: middle;
    }
    .card {
      vertical-align: middle;
    }
    .icon-guanbi {
      color: $color-icon-bg;
      margin-left: 10px;
      font-size: 20px;
      margin-top: 1px;
      vertical-align: middle;
      display: none;
    }
  }
  &:hover, &:focus {
    .icon-guanbi {
      display: inline-block;
    }
  }
  &:focus-within {
    .icon-guanbi {
      display: inline-block;
    }
  }
}
</style>
