export default {
  EFT: [
    { code: 'banco_agrario', name: 'BANCO AGRARIO' },
    { code: 'banco_av_villas', name: 'BANCO AV VILLAS' },
    { code: 'banco_bbva_colombia_s.a.', name: 'BANCO BBVA COLOMBIA S.A.' },
    { code: 'banco_caja_social', name: 'BANCO CAJA SOCIAL' },
    { code: 'banco_colpatria', name: 'BANCO COLPATRIA' },
    { code: 'banco_cooperativo_coopcentral', name: 'BANCO COOPERATIVO COOPCENTRAL' },
    { code: 'banco_corpbanca_s.a', name: 'BANCO CORPBANCA S.A' },
    { code: 'banco_davivienda', name: 'BANCO DAVIVIENDA' },
    { code: 'banco_de_bogota', name: 'BANCO DE BOGOTA' },
    { code: 'banco_de_occidente', name: 'BANCO DE OCCIDENTE' },
    { code: 'banco_falabella_', name: 'BANCO FALABELLA' },
    { code: 'banco_gnb_sudameris', name: 'BANCO GNB SUDAMERIS' },
    { code: 'banco_pichincha_s.a.', name: 'BANCO PICHINCHA S.A.' },
    { code: 'banco_procredit', name: 'BANCO PROCREDIT' },
    { code: 'bancolombia', name: 'BANCOLOMBIA' },
    { code: 'bancoomeva_s.a.', name: 'BANCOOMEVA S.A.' },
    { code: 'citibank_', name: 'CITIBANK' },
    { code: 'itau', name: 'ITAU' },
    { code: 'nequi', name: 'NEQUI' },
  ],
  Przelewy24: [
    { code: '20', name: 'Santander-Przelew24' },
    { code: '26', name: 'P_ac_ z Inteligo' },
    { code: '31', name: 'P_ac_ z iPKO (PKO BP)' },
    { code: '33', name: 'BNP Paribas' },
    { code: '43', name: 'Bank PEKAO S.A.' },
    { code: '45', name: 'Credit Agricole' },
    { code: '49', name: 'ING Bank _l_ski' },
    { code: '52', name: 'Konto Inteligo' },
    { code: '53', name: 'Bank PKO BP (iPKO)' },
    { code: '54', name: 'Santander' },
    { code: '64', name: 'Toyota Bank' },
    { code: '65', name: 'Bank PEKAO S.A.' },
    { code: '69', name: 'Volkswagen Bank' },
    { code: '85', name: 'Bank Millennium' },
    { code: '88', name: 'P_ac_ z Alior Bankiem' },
    { code: '90', name: 'Nest Bank' },
    { code: '95', name: 'Credit Agricole' },
    { code: '99', name: 'P_ac_ z BO_' },
    { code: '112', name: 'P_ac_ z ING' },
    { code: '119', name: 'P_ac_ z CitiHandlowy' },
    { code: '129', name: 'Alior - Raty' },
    { code: '131', name: 'P_ac_ z Plus Bank' },
    { code: '136', name: 'mBank - Raty' },
    { code: '141', name: 'e-transfer Pocztowy24' },
    { code: '143', name: 'Banki Sp _dzielcze' },
    { code: '144', name: 'Bank Nowy BFG S.A.' },
    { code: '153', name: 'Getin Bank' },
    { code: '154', name: 'BLIK' },
    { code: '158', name: 'Noble Pay' },
    { code: '161', name: 'P_ac_ z IdeaBank' },
    { code: '185', name: 'EnveloBank' },
    { code: '222', name: 'NestPrzelew' },
    { code: '223', name: 'BNP Paribas P_ac_ z Pl@net' },
    { code: '243', name: 'mBank - mTransfer' },
    { code: '266', name: 'P24now' },
    { code: '270', name: 'mBank (Us_ugaITP)' },
    { code: '271', name: 'ING Bank Å lÄ ski (Us_uga ITP)' },
    { code: '272', name: 'BNP Paribas (Us_uga ITP)' },
    { code: '274', name: 'PKO BP (Us_uga ITP)' },
    { code: '275', name: 'Santander (Us_uga ITP)' },
    { code: '279', name: 'Inteligo (Us_uga ITP)' },
    { code: '280', name: 'mBank - Raty' },
  ],
};
