<template>
  <el-drawer
    :withHeader="false"
    :visible.sync="open"
    :close-on-press-escape="false"
    :show-close="false"
    :moal="false"
    custom-class="no-animation"
    size="100%">
    <div class="rhcn-drawer-header-wrapper">
      <div class="rhcn-drawer-header">
        <template v-if="app.brandInfo && app.brandInfo.logoImage">
          <img height="36" style="width: auto;" v-if="showLogo" :src="app.brandInfo.logoImage" />
        </template>
        <template v-else-if="app.brandInfo && !app.brandInfo.init">
          <img v-if="showLogo" src="../../assets/images/logo.png" height="36" style="width: auto;" />
        </template>
        <slot name="headerRight"></slot>
        <div v-if="showClose" class="close-wrapper">
          <span class="drawer-line"></span>
          <rhcn-button class="icon-button rhcn-close-button" @click="closeDrawer" size="small"><i class="el-icon-close"></i></rhcn-button>
        </div>
      </div>
    </div>
    <div class="drawer-form-wrapper">
      <slot></slot>
    </div>
  </el-drawer>
</template>
<script>
import { mapGetters } from 'vuex';

import RhcnButton from '@/components/form/button.vue';

export default {
  name: 'rhcn-drawer',
  computed: {
    showLogo() {
      return this.$route.meta.showLogo === undefined ? true : this.$route.meta.showLogo;
    },
    ...mapGetters(['app']),
  },
  components: {
    RhcnButton,
  },
  emits: ['on-close'],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    closeDrawer() {
      this.$emit('on-close');
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/variables.scss';
.rhcn-drawer-header-wrapper {
  border-bottom: 4px solid $color-text-bg;
  position: fixed;
  width: 100%;
  background-color: #FFF;
  z-index: 9;
}
.rhcn-drawer-header {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0 auto;
  max-width: 1250px;
  .close-wrapper {
    display: flex;
    align-items: center;
    .drawer-line {
      width: 2px;
      height: 42px;
      display: inline-block;
      background: $color-text-bg;
      border: 0;
      margin: 0 10px 0 0;
    }
    .el-button.icon-button.rhcn-close-button {
      font-size: 23px;
      width: 27px !important;
      height: 27px !important;
      min-width: 27px !important;
      border-radius: 4px;
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        display: flex;
      }
    }
    .el-icon-close {
      font-weight: bold;
      font-size: 20px;
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
}
.drawer-form-wrapper {
  padding-top: 74px;
}
@media screen and (max-width: 1140px) {
  .rhcn-drawer-header {
    padding: 0 10px;
  }
}
</style>
